import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { switchMap, map } from 'rxjs/operators';
import { SortColumn, SortDirection } from './sortable.directive';
import { AppService } from '../app.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


export interface Request_Model {
    id: number;
    form_data: any;
    result: any;
    created_at: string;
}

interface SearchResult {
    requests: Request_Model[];
    total: number;
}

interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: SortColumn;
    sortDirection: SortDirection;
}


const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(requests: Request_Model[], column: SortColumn, direction: string): Request_Model[] {
    if (direction === '' || column === '') {
        return requests;
    } else {
        return [...requests].sort((a, b) => {
            const res = compare(`${a[column]}`, `${b[column]}`);
            return direction === 'asc' ? res : -res;
        });
    }
}


function matches(request: Request_Model, term: string, pipe: PipeTransform) {
    return request.form_data.type.toLowerCase().includes(term.toLowerCase())
        || request.created_at.toLowerCase().includes(term.toLowerCase())
        || request.id.toString().toLowerCase().includes(term.toLowerCase())
        || pipe.transform(request.form_data.ids.length).includes(term);

}

@Injectable({ providedIn: 'root' })
export class CoreService {
    private collectionr_endpoint = 'api/collection-report/';
    private dsr_endpoint = 'api/ds-report/';
    private rtw_endpoint = 'api/rtw-report/';
    private pts_endpoint = 'api/gate-pass-item/';//Production to store transfer end point
    private rfp_endpoint = 'api/uploaded-item/';//Receive from production end point
    private stp_endpoint = 'api/store-to-prod/';//Store to production transfer end point
    private sts_endpoint = 'api/store-to-store/'; //Store to store transfer end point
    private rtp_endpoint = 'api/return-to-prod/';//Return to Production end point
    private stock_adj_endpoint = 'api/stock_adjustments/';//Stock Adjustment end point
    private upload_stock_endpoint = 'api/upload-process-report/'//Upload Stock endpoint
    private serviceURIAUTH = "auth/me/";
    private users_endpoint = "api/users"
    public _config = environment;
    public url = this._config.ServerURL;
    private _search$ = new Subject<void>();
    private _requests$ = new BehaviorSubject<Request_Model[]>([]);
    private _total$ = new BehaviorSubject<number>(0);
    public COUNTRIES: any;

    private _state: State = {
        page: 1,
        pageSize: 20,
        searchTerm: '',
        sortColumn: '',
        sortDirection: ''
    };


    constructor(private service: AppService, private pipe: DecimalPipe, private _router : Router) {
    }

    call() {
        this._search$.pipe(
            // tap(() => this._loading$.next(true)),
            // debounceTime(200),
            switchMap(() => this._search()),
            // delay(200),
            // tap(() => this._loading$.next(false))
        ).subscribe(result => {
            this._requests$.next(result.requests);
            this._total$.next(result.total);
        });

        this._search$.next();
    }
    get requests$() { return this._requests$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    // get loading$() { return this._loading$.asObservable(); }
    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }

    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize }); }
    set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
    set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    private _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _search(): Observable<SearchResult> {
        const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

        // 1. sort

        if (!this.COUNTRIES) {
            this.COUNTRIES = [];
        }
        let requests = sort(this.COUNTRIES, sortColumn, sortDirection);

        // 2. filter
        // if(requests){
        requests = requests.filter(request => matches(request, searchTerm, this.pipe));
        const total = requests.length;
        // 3. paginate
        requests = requests.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        return of({ requests, total });
        // }
    }


    getusers() { return this.service.get(`${this.users_endpoint}`); }
    postusers(params) { return this.service.post(`auth/users/create/`, params); }
    post_update_users(params) { return this.service.post(`prediction/update-user/`, params); }
    putusers(params) { return this.service.put(`auth/users/create/`, params.id, params); }
    deleteusers(params) { return this.service.delete(`${this.users_endpoint}`, params); }
    get_me() { return this.service.get(`${this.serviceURIAUTH}`); }
    reset_password(params) { return this.service.post(`user-auth/password/`, params); }
    get_json() { return this.service.getJSON();}
    get_request_logs(params) {
        return this.service.post_map(`${this.collectionr_endpoint}`, params).pipe(map(
            res => {
                if (res.status == 200) {
                    this.COUNTRIES = res['result'];
                    return this.COUNTRIES;
                }
            }))
    }
    get_dsr(params) {
        return this.service.post_map(`${this.dsr_endpoint}`, params).pipe(map(
            res => {
                if (res.status == 200) {
                    this.COUNTRIES = res['result'];
                    return this.COUNTRIES;
                }
            }))
    }
    get_rtw(params) {
        return this.service.post_map(`${this.rtw_endpoint}`, params).pipe(map(
            res => {
                if (res.status == 200) {
                    this.COUNTRIES = res['result'];
                    return this.COUNTRIES;
                }
            }))
    }
    get_rfp(params) {
        return this.service.post_map(`${this.rfp_endpoint}`, params).pipe(map(
            res => {
                if (res.status == 200) {
                    this.COUNTRIES = res['result'];
                    return this.COUNTRIES;
                }
            }))
    }

    get_pts(params) {
        return this.service.post_map(`${this.pts_endpoint}`, params).pipe(map(
            res => {
                if (res.status == 200) {
                    this.COUNTRIES = res['result'];
                    return this.COUNTRIES;
                }
            }))
    }

    get_stp(params) {
        return this.service.post_map(`${this.stp_endpoint}`, params).pipe(map(
            res => {
                if (res.status == 200) {
                    this.COUNTRIES = res['result'];
                    return this.COUNTRIES;
                }
            }))
    }

    //Store to Store end point
    get_sts(params) {
        return this.service.post_map(`${this.sts_endpoint}`, params).pipe(map(
            res => {
                if (res.status == 200) {
                    this.COUNTRIES = res['result'];
                    return this.COUNTRIES;
                }
            }))
    }

    //Return to production
    get_rtp(params) {
        return this.service.post_map(`${this.rtp_endpoint}`, params).pipe(map(
            res => {
                if (res.status == 200) {
                    this.COUNTRIES = res['result'];
                    return this.COUNTRIES;
                }
            }))
    }

    //Stock Adjustment api
    get_stock_adj(params) {
        return this.service.post_map(`${this.stock_adj_endpoint}`, params).pipe(map(
            res => {
                if (res.status == 200) {
                    this.COUNTRIES = res['result'];
                    return this.COUNTRIES;
                }
            }))
    }

    get_upload_stock(params) {
        return this.service.post_map(`${this.upload_stock_endpoint}`, params).pipe(map(
            res => {
                if (res.status == 200) {
                    this.COUNTRIES = res['result'];
                    return this.COUNTRIES;
                }
            }))
    }

    outsession(data) {
        if (data == 0) {
          localStorage.removeItem("currentUser");
          this._router.navigate(["/login"]);
        }
      }
}


