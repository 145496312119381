import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from "../environments/environment";


@Injectable()
export class AppService {
    constructor(private http: HttpClient) { }
    public _config = environment;

    public url = this._config.ServerURL;

    public get(_apiUrl, item?: any): Observable<any> {
        return this.http.get(`${this.url}` + _apiUrl, this._header()).pipe(
            map(this.extractData)
            ,catchError(this.handleError))
    }

    public getJSON(): Observable<any> {
        return this.http.get('assets/test.json');
      }

    public post(_apiUrl, item: any): Observable<any> {
        return this.http.post(`${this.url}` + _apiUrl, item.data, this._header()).pipe(
            map(this.extractData)
            ,catchError(this.handleError));
    }


    public post_map(_apiUrl, item: any): Observable<any> {
        return this.http.post(`${this.url}` + _apiUrl, item.data, this._header());
    }

    public patch(_apiUrl: any, item: any): Observable<any> {
        return this.http.patch(`${this.url}` + _apiUrl, item, this._header() ).pipe(
          map(this.extractData),
          catchError(this.handleError)
        );
      }

    public put(_apiUrl, itemId: number, item: any): Observable<any> {
        return this.http.put(`${this.url}` + _apiUrl + itemId + `/`, item, this._header()).pipe(
            map(this.extractData)
            ,catchError(this.handleError));
    }

    public delete(_apiUrl, item: any): Observable<any> {
        return this.http.delete(`${this.url}` + _apiUrl + item + `/`, this._header()).pipe(
            map(this.extractData)
            ,catchError(this.handleError));
    }


    _header() {
        let user = localStorage.getItem('currentUser');
        if (!!user) {
            let temp = JSON.parse(user)
            let token = temp['result']['token'];
            const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8','Authorization': 'JWT ' + token});
            const options = { headers: headers };
            return options;
        }
    }

    public extractData(res: Response) {
        let body = res;
        return body;
    }

    public handleError(error: Response | any) {
        return throwError(error);
    }


}


