import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LogoutComponent } from './auth/logout/logout.component';


const routes: Routes = [
  // {path: '', redirectTo: "core", pathMatch: 'full'},
  // {path: 'login', component: AuthenticationComponent},
  // {path: 'core', component: CoreComponent},
  { path: 'login', loadChildren: './auth/auth.module#AuthModule' },
  { path: 'logout', component: LogoutComponent },
  {
    path: '',
    loadChildren: './core/core.module#CoreModule'
  },
  {
    path: 'core',
    loadChildren: './core/core.module#CoreModule'
  },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  
  exports: [RouterModule]
})
export class AppRoutingModule { }
