import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CoreService } from 'src/app/core/core.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _router: Router, private service: CoreService) {
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (!!localStorage.getItem('currentUser') == true) {
             return true;
        }
            this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
    }
 
}