import { Injectable } from "@angular/core";
import {  HttpClient,  HttpHeaders } from "@angular/common/http";
import { Observable, throwError  } from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import { environment } from "../../../environments/environment";

@Injectable()
export class AuthenticationService {
public config = environment;
    constructor(private http: HttpClient) { }
    login(username: string, password: string) {

        let url = this.config.ServerURL + 'auth/jwt/create/';
        // let headers = new Headers({ 'Content-Type': 'application/json' });
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              
            })
          };
        return this.http.post(url, { username: username, password: password }, httpOptions)
            .pipe(
                map((res: Response) => { 
                    return res;
                 } )
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
    }

    signup(param) {
        let user = localStorage.getItem('currentUser');
        let url = this.config.ServerURL + 'accounts/create_user/';
        if (!!user) {
            let temp = JSON.parse(user)
            let token = temp['token'];
        
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': 'jwt ' + token,
            })
          };
        return this.http.post(url, param, httpOptions)
            .pipe(
                map((res: Response) => { 
                    return res;
                 } )
            ,catchError((e: any, caught) => throwError(this.errorHandler(e))))
    }
}

    errorHandler(error: any): void {
        return error;
    }

    isAuth() {
        const user = localStorage.getItem('currentUser');
        return !!user;
    }
    logout() {
        localStorage.removeItem('currentUser');
    }
}