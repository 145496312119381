import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS   } from '@angular/common/http';


import { AuthRoutingModule } from './auth-routing.routing';
import { AuthComponent } from './auth.component';
import { LogoutComponent } from './logout/logout.component';
import { AuthGuard } from './_guards/auth.guard';
import { AuthenticationService } from './_services/authentication.service';
import { JwtInterceptor } from './interceptors/jwt.interceptor';

import { NgxUiLoaderModule } from  'ngx-ui-loader';


@NgModule({
    declarations: [
        AuthComponent,
        LogoutComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule ,
        AuthRoutingModule,
        NgxUiLoaderModule,
        
    ],
    providers: [
        AuthGuard,
        AuthenticationService,    
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }       
    ],

})

export class AuthModule {
}