import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { AuthenticationService } from "./_services/authentication.service";

import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: ".m-grid.m-grid--hor.m-grid--root.m-page.h-100  ",
  templateUrl: "./templates/login-1.component.html",
  styleUrls: ["./templates/login.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {
  model: any = {};
  loading = false;
  error = "";
  returnUrl: "collection-report";
  currentYear: number = new Date().getFullYear();
  public disclaimer = false;
  // secret_key;
  // user_key;
  suberror = [];

  myStyle: object = {};
  public myParams: object = {};

  

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthenticationService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService
  ) {}
  ngOnInit() {
    this.model.remember = true;
    this.returnUrl = this._route.snapshot.queryParams["returnUrl"] || "/";
    this._router.navigate([this.returnUrl]);
  

  }

  signin(formm) {
    this.ngxService.start();

          this._authService
            .login(this.model.username, this.model.password)
            .subscribe(
              data => {
                if (data) {
                  localStorage.setItem("currentUser", JSON.stringify(data));
                  this._router.navigate(["collection-report"]);
                  this.ngxService.stop();
                }
              },
              error => {
                if (error.status == 0) {
                  this.ngxService.stop();
                  
                  this.toastr.error('Service Temporarily Unavailable, please try again');
                  this.outsession(error.status);
              }
              else if (error.status == 400 || error.status == 401) {
                  this.ngxService.stop();
                  
                  if(error.error['non_field_errors']){
                      this.toastr.error(error.error['non_field_errors'][0]);
                  }
                  else{
                  this.toastr.error('Invalid Credentials');
                  }
                 
              }
              else {
                  this.ngxService.stop();
                  this.toastr.error('Internal Server Error');                  
              }
             
              }
            );
        
  }

  outsession(data) {
    if (data == 0) {
      localStorage.removeItem("currentUser");
      this._router.navigate(["/login"]);
    }
  }

  alertpopup = false;
  messageAlert = "";

  removeAlert() {
    this.alertpopup = false;
    this.error = "";
  }

  showAlert(target) {
    this.alertpopup = true;
    this.error = target;
  }
}
